<template lang="pug">
  div
    v-data-table(
      :items="hericaClientList"
      :loading="hericaClientGetting"
      :headers="headers"
      single-expand
      dense
    )
      template(v-slot:item="{ item, expand, isExpanded }")
        tr
          td {{ item.name }}
          td {{ item.email }}
          td
            v-switch.pa-0.ma-0(
              small
              hide-details
              color="primary"
              :input-value="item.active"
            )
          td
            base-tooltip(
              color="success"
              dark
              bottom
              x-small
              text
              tooltip="Reset Password"
              @click="resetPassword(item.id)"
            )
              v-icon.spin-btn mdi-recycle
            v-btn(
              :class="!isExpanded || 'is-expanded'"
              color="blue-grey darken-3"
              dark
              x-small
              text
              @click="expand(!isExpanded)"
            )
              v-icon mdi-chevron-down
      template(v-slot:expanded-item="{ item }")
        tr
          td(:colspan="headers.length")
            v-card.ma-1
              v-container
                v-simple-table(dense)
                  tbody
                    tr
                      td {{ item.info.name }}
                      td {{ item.info.contactno1 }}
                      td {{ item.info.contactno2 }}
                    tr
                      td(colspan="3") {{ makeAddress(item.info) }}
</template>
<style lang="scss" scope>
    .v-btn.is-expanded .v-icon {
      transform: rotate(-180deg);
    }
    .v-icon.spin-btn {
      transition-duration: 1s;
      &:hover {
        transform: rotate(-180deg);
        color: #F9A825;
      }
    }
</style>
<script>
import hericaClientRepository from '@/repositories/herica-client.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [hericaClientGetVars, hericaClientGetVarNames] = requestVars({ identifier: 'herica-client' })
const [hericaClientChangePasswordPutVars, hericaClientChangePasswordPutVarNames] = requestVars({ identifier: 'herica-client-change-password', request: 'put', hasData: false })
const hericaClientGetHandler = new VueRequestHandler(null, hericaClientGetVarNames)
const hericaClientChangePasswordPutHandler = new VueRequestHandler(null, hericaClientChangePasswordPutVarNames)

const tableVars = () => ({
  headers: [
    { text: 'Username', value: 'name' },
    { text: 'Email', value: 'email' },
    { text: 'Active', value: 'active' },
    { text: 'Config', sortable: false },
  ],
})

export default {
  name: 'TableHericaClient',
  data: () => ({
    ...tableVars(),
    ...hericaClientGetVars,
    ...hericaClientChangePasswordPutVars,
  }),
  created () {
    this.getHericalClients()
    this.initWebsocketEvents()
  },
  methods: {
    resetPassword (id) {
      const handler = hericaClientChangePasswordPutHandler
      const repository = hericaClientRepository.resetPassword
      handler.setVue(this)
      handler.execute(repository, [id])
    },
    getHericalClients () {
      const handler = hericaClientGetHandler
      const repository = hericaClientRepository.index
      const params = this.getHericaClientIndexParams()
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    getHericaClientIndexParams () {
      return {
        with: 'info',
      }
    },
    initWebsocketEvents () {
      const { echo } = this.$store.state.websocket
      echo.private('herica.database.event')
        .listen('HericaUserDBStoreEvent', this.dbStoreEvent)
    },
    dbStoreEvent ({ data }) {
      if (this.$objectEmpty(data)) return
      this.hericaClientList.push(data)
    },
    makeAddress (args) {
      const { purok = null, brgy = null, city = null, province = null } = args || {}
      return [purok, brgy, city, province]
        .filter(item => Boolean(item))
        .join(', ')
    },
  },
}
</script>
